// let baseURL = 'http://192.168.0.240:9011/'
// let baseURL = 'http://192.168.0.158:9000/'  
let baseURL = 'https://manager.pan-xiang.com'


let getCooperateData = 'getCooperateData'           // 首页-合作伙伴
let getCultureData =    'getCultureData'            //企业文化  
let getCertificateData = 'getCertificateData'   //认证证书
let getSaleData = 'getSaleData'                         //获取售后支持数据
let getMobileSolutionData = 'getMobileSolutionData'                 //获取解决方案列表
let getMobileCarouselData= 'getMobileCarouselData'  // 轮播图 
let getMobileIndexData = 'getMobileIndexData' //首页数据
let getArticleData = 'getArticleData'   //获取文章数据
let getEvolveData = 'getEvolveData'                 //发展历程
let getNewsData = 'getNewsData'                     // 首页-新闻列表
let getHonourData = 'getHonourData'                     //荣誉资质
export default {
    'baseURL':baseURL,
    'getCooperateData':getCooperateData,
    'getCultureData':getCultureData,
    'getCertificateData':getCertificateData,
    'getSaleData':getSaleData,
    'getMobileSolutionData':getMobileSolutionData,
    'getMobileCarouselData':getMobileCarouselData,
    'getMobileIndexData':getMobileIndexData,
    'getArticleData':getArticleData,
    'getEvolveData':getEvolveData,
    'getNewsData':getNewsData,
    'getHonourData':getHonourData
}