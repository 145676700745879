import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'amfe-flexible'
import Vant from 'vant';
import 'vant/lib/index.css';
import * as axios from '@/libs/axios'
import GloablUrl from './api/GloablUrl'
Vue.use(Vant);
Vue.use(ElementUI)
Vue.prototype.PX_Get = axios.PX_Get
Vue.prototype.PX_Post = axios.PX_Post
Vue.prototype.PX_Put = axios.PX_Put
Vue.prototype.PX_Delete = axios.PX_Delete
Vue.prototype.GloablUrl = GloablUrl
Vue.$httpRequestList=[];
Vue.config.productionTip = false
//弹出框禁止滑动
Vue.prototype.stopScroll = function () {
  var defaultNo = function (e) { e.preventDefault() }
  document.body.style.overflow = 'hidden'
  // 禁止页面滑动
  document.addEventListener('touchmove', defaultNo, false)
}
 
//弹出框可以滑动
Vue.prototype.canScroll = function () {
  var defaultNo = function (e) {
    e.preventDefault()
  }
  // 出现滚动条
  document.body.style.overflow = ''
  document.removeEventListener('touchmove', defaultNo, false)
}

// Vue.config.errorHandler = function (err, vm, info) {
//   console.log('全局捕获 err >>>', err)
//   console.log('全局捕获 vm >>>', vm)
//   console.log('全局捕获 info >>>', info)
// }

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
