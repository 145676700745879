
import Vue from 'vue'
// 导入插件
import VueRouter from 'vue-router'
Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'home',
    component: resolve => require(['../views/index.vue'], resolve)
},
{
    path: '/about',
    name: 'about',
    component: resolve => require(['../views/about.vue'], resolve)
},
{
    path: '/contact',
    name: 'contact',
    component: resolve => require(['../views/contact.vue'], resolve)
},
{
    path: '/serve',
    name: 'serve',
    component: resolve => require(['../views/serve.vue'], resolve)
},
{
    path: '/lianxi',
    name: 'demo',
    component: resolve => require(['../views/lianxi.vue'], resolve)
},
]
const router = new VueRouter({
    routes,
    mode: 'hash', //hash  history
    scrollBehavior(to, from, savedPosition) {
        // 期望滚动到哪个位置
        return {
          x: 0,
          y: 0
        }
      },
})



export default router